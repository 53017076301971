import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'los-angeles',
  title: 'Los Angeles',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          'Our team is available to host walk-ins or visitors by appointment on Monday–Friday, from 10 a.m. to 5 p.m. Appointments are recommended.',
          '806 Mateo St',
          'Los Angeles, CA 90021',
          {render: () => (
            <a className='phone' href='tel:3102992441'>310.299.2441</a>
          )},
          {render: () => (
            <a className='email' href='mailto:losangeles@spacetheory.com'>losangeles@spacetheory.com</a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('los-angeles/1.jpg')}],
    }
  ],
  structuredData: {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Space Theory",
    "description": "An intelligent kitchen system from the founders of Henrybuilt. Made to order in Seattle for homes throughout North America.",
    "url": "https://www.spacetheory.com/",
    "logo": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
    "image": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
    "hasMap": "https://maps.app.goo.gl/Qo2QS47dc7rZaNN28",
    "branchOf": {
      "@type": "Organization",
      "name": "Space Theory",
      "url": "https://www.spacetheory.com"
    },
    "location": [
      {
        "@type": "LocalBusiness",
        "@id": "https://www.spacetheory.com/page/los-angeles",
        "name": "LA Location",
        "url": "https://www.spacetheory.com/page/los-angeles",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "806 Mateo St",
          "addressLocality": "Los Angeles",
          "addressRegion": "CA",
          "postalCode": "90021",
          "addressCountry": "United States"
        },
        "telephone": "+1-310-299-2441",
        "openingHours": "By Appointment Only",
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "34.03354390641235",
          "longitude": "-118.23134775189875"
        }
      }
    ],
    "sameAs": [
      "https://www.facebook.com/spacetheorykitchens/",
      "https://www.instagram.com/space.theory/"
    ]
  }
};
